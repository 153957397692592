import React from "react";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import Banner from "./components/Banner/Banner";
import Subscribe from "./components/Subscribe/Subscribe";
import Banner2 from "./components/Banner/Banner2";
// import Footer from "./components/Footer/Footer";
import FollowUs from "./components/followUs/follow";
import WhyChooseUs from "./components/whychooseus/whychooseus";

import Tools from "./components/Tools/tools";
import Upcoming from "./components/upcoming/upcoming";

const Main = () => {
  return (
    <main className="overflow-x-hidden bg-white text-dark">
      <Hero />
      <Banner />
      <Services />
      <Tools/>
      <WhyChooseUs/>
       <Upcoming/>
      <Subscribe />
      <Banner2 />
      <FollowUs/>
      {/* <Footer /> */}
    </main>
  );
};

export default Main;