import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const Upcoming = () => {
  const courses = [
    {
      title: "Data Engineering Course",
      startDate: new Date("2024-10-12T00:00:00"),
    },
    {
      title: "Data Science with Python",
      startDate: new Date("2024-10-26T00:00:00"),
    },
    {
      title: "Full Stack Development",
      startDate: new Date("2024-10-26T00:00:00"),
    },
  ];

  const [timers, setTimers] = useState(courses.map(() => ({ days: 0, hours: 0, minutes: 0, seconds: 0 })));

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimers = courses.map((course) => {
        const now = new Date();
        const timeDiff = course.startDate - now;

        // Calculate time left
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
      });
      setTimers(newTimers);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <section className="bg-white py-14 md:py-24">
      <div className="container mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-8 text-blue-900"
        >
          Advance Your Career with Our Upcoming Courses
        </motion.h2>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <motion.table
            className="min-w-full border border-gray-300 rounded-lg shadow-md"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <thead>
              <tr className="bg-blue-100 text-blue-700">
                <th className="border border-gray-300 px-6 py-4 text-center">Courses</th>
                <th className="border border-gray-300 px-6 py-4 text-center">Starting Date</th>
                <th className="border border-gray-300 px-6 py-4 text-center">Time Left</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <motion.tr
                  key={index}
                  className="border-b transition duration-200 hover:bg-blue-50"
                  whileHover={{ scale: 1.02, boxShadow: "0 4px 20px rgba(0,0,0,0.2)" }}
                >
                  <td className="border border-gray-300 px-6 py-4 text-center">{course.title}</td>
                  <td className="border border-gray-300 px-6 py-4 text-center">{formatDate(course.startDate)}</td>
                  <td className="border border-gray-300 px-6 py-4 text-center">
                    {timers[index].days} days, {timers[index].hours} hours, {timers[index].minutes} minutes, {timers[index].seconds} seconds
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </motion.table>
        </motion.div>
      </div>

      {/* Mobile Styles */}
      <style jsx>{`
        @media (max-width: 640px) {
          table {
            display: block;
            overflow-x: auto;
          }

          thead {
            display: none;
          }

          tbody tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            border: none;
            border-bottom: 1px solid #ccc;
          }

          tbody td {
            border: none;
            padding: 0.5rem;
            text-align: left;
            font-size: 1rem;
          }

          tbody td::before {
            content: attr(data-label);
            font-weight: bold;
            text-transform: uppercase;
            margin-right: 0.5rem;
          }
        }
      `}</style>
    </section>
  );
};

export default Upcoming;
